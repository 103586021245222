/**
 * Resource post Template
 */
/* Import section */
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { IconContext } from "react-icons"
import { BsFillMicFill, BsCloudDownload } from "react-icons/bs"
import { siteMetadata } from "../../gatsby-config"
import Boxctavideo from "../components/boxctavideo"
import Ctabuttonwitharrow from "../components/ctabuttonwitharrow"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Socialshare from "../components/socialshare"
import Titledecorative from "../components/titledecorative"
import "../styles/templates/resourcepost.scss"

/* Declaration function */
const ResourcePostTemplate = ({ data }) => {

  //Const data page
  const dataPage = data.wpResource
  const dataAcf = dataPage.acfPostResource
  const dataCatResources = dataPage.catsresources.nodes[0]
  const colorCatResource = dataCatResources.acfCatResources.colorTag
  const nameCatResource = dataCatResources.name
  const slugCatResource = dataCatResources.slug
  const ctaLabelBack = 'Back to '+nameCatResource
  const urlResource = siteMetadata.siteUrl+'/'+slugCatResource+'/'+dataPage.slug
  const introResource = dataAcf.introResource
  var ctaLabelVideo = ""
  var ctaLabelAudio = ""
  var ctaLabelFile = ""

  if(slugCatResource === "webinars") {
    ctaLabelVideo='Watch Webinar'
  }

  if(slugCatResource === "podcasts") {
    ctaLabelAudio='Listen Podcast'
  }

  if(slugCatResource === "whitepapers") {
    ctaLabelFile='Download Whitepaper'
  }

  if(slugCatResource === "case-studies") {
    ctaLabelFile='Download Case Study'
  }

  return(
    <Layout>
    <Seo
      title={dataPage.seo.title}
      description={dataPage.seo.metaDesc}
    />

    <section className="section-article-resource top-section">
      <div className="back-resources">
        <Ctabuttonwitharrow ctaLabel={ctaLabelBack} ctaLink={`/${slugCatResource}`} colorStyle="white-format" specificStyle="text-arrow" arrow="left" />
      </div>
      <div className="wrap-article-resource">
        <article>
          <header className="header-resource">
            <p className={`type-resource ${colorCatResource}`}><span>{nameCatResource}</span></p>
            <Titledecorative titleType="h1" title={dataPage.title} />
            <div className="box-share">
              <Socialshare urlArticle={urlResource} leadArticle={dataPage.seo.metaDesc} />
            </div>
          </header>
          <section className="content-resource">
            {introResource !== "" &&
            <div className="lead-content-resource">
              <div className="post-content" dangerouslySetInnerHTML={{ __html:introResource}} />
            </div>
            }
            <div className="main-content-resource">
              <div className="post-content" dangerouslySetInnerHTML={{ __html:dataAcf.contentResource}} />
            </div>
          </section>
        </article>
        <aside>
            {dataAcf.fileResource !== null &&
             <div className="wrap-box-resource">
                {dataAcf.pictureResource !== null ? (
                  <div className="box-resource box-img-link card-clickable">
                    <div className="box-image-resource">
                      <GatsbyImage image={dataAcf.pictureResource.localFile.childImageSharp.gatsbyImageData} alt={`image ${dataPage.title}`} />
                    </div>
                    <div className="cta-link-file">
                      <a className="link-file" href={`/pdf/${dataAcf.fileResource}`} target="_blank" rel="noreferrer">{ctaLabelFile}</a>
                    </div>
                    <a href={`/pdf/${dataAcf.fileResource}`} target="_blank" rel="noreferrer"><span className="span-cta-card">{ctaLabelFile}</span></a>
                  </div>
                ) : (
                  <div className="box-resource box-media-link card-clickable">
                    <div className="box-cta-media">
                      <div className="cta-media">
                        <a href={`/pdf/${dataAcf.fileResource}`} target="_blank" rel="noreferrer" className="button-player-media">
                          <span className="wrap-player">
                            <IconContext.Provider value={{ className: "svg-player" }}>
                              <BsCloudDownload />
                            </IconContext.Provider>
                          </span>
                        </a>
                        <p>{ctaLabelFile}</p>
                      </div>
                      <a href={`/pdf/${dataAcf.fileResource}`} target="_blank" rel="noreferrer"><span className="span-cta-card">{ctaLabelFile}</span></a>
                    </div>
                  </div>
                )}
             </div>
            }
            {dataAcf.audioLinkResource !== null &&
             <div className="wrap-box-resource">
                <div className="box-resource box-media-link card-clickable">
                  <div className="box-cta-media">
                    <div className="cta-media">
                      <a href={dataAcf.audioLinkResource} target="_blank" rel="noreferrer" className="button-player-media">
                        <span className="wrap-player">
                          <IconContext.Provider value={{ className: "svg-player" }}>
                            <BsFillMicFill />
                          </IconContext.Provider>
                        </span>
                      </a>
                      <p>{ctaLabelAudio}</p>
                    </div>
                  </div>
                  <a href={dataAcf.audioLinkResource} target="_blank" rel="noreferrer" aria-label={ctaLabelAudio}><span className="span-cta-card">{ctaLabelAudio}</span></a>
                </div>
             </div>
            }
            {dataAcf.videoLinkResource !== null &&
            <div className="wrap-box-resource">
              <Boxctavideo index={dataPage.slug} text_cta={ctaLabelVideo} video_url={dataAcf.videoLinkResource} classBoxCtaVideo="box-resource box-media-link" classbtn_video="roundsmall" classbtn_color="white" />
            </div>
            }
        </aside>
      </div>
    </section>
  </Layout>
  )
}

/* Export template informations */
export default ResourcePostTemplate

//QUERY GRAPHSQL ON POST
export const query = graphql`
  query($id: Int!) {
    wpResource(databaseId: { eq: $id }) {
      databaseId
      title
      slug
      acfPostResource {
        audioLinkResource
        contentResource
        fileResource
        hubspotIdFormResource
        introResource
        videoLinkResource
        pictureResource {
          localFile {
            childImageSharp {
              gatsbyImageData (layout: CONSTRAINED, width: 800)
            }
          }
        }
      }
      catsresources {
        nodes {
          acfCatResources {
            colorTag
            titleH1Tag
          }
          name
          slug
        }
      }
      seo {
        title
        metaDesc
      }
    }
  }
`
